<template lang="pug">
.block-container
  .block-container-title {{ title }}
  .block-cell-container(v-if="items?.length")
    .block-cell(v-for="(item, index) in items", :key="index")
      .cell-title {{ item.title }}
      .cell-content {{ item.content }}
  slot
</template>

<script>
export default {
  name: "OnlineSettingBlock",
  props: {
    title: { type: String, default: "" },
    items: { type: Array, default: () => [] },
  },
};
</script>

<style lang="sass" scoped>
.block-container
  display: flex
  flex-direction: row
  align-items: flex-start
  gap: 20px
  .block-container-title
    font-size: 1rem
    font-weight: 500
    color: black
    min-width: 100px
.block-cell-container
  display: flex
  flex-direction: column
  gap: 20px
  flex-grow: 1
.block-cell
  display: flex
  flex-direction: column
  gap: 2px
  .cell-title
    color: #4b5563
    font-size: 0.8rem
  .cell-content
    font-size: 1rem
    font-weight: 500
    color: #111827
</style>