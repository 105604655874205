<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Edit Business Name
    v-form(@submit.prevent="save")
      v-card-text
        div Naming Guidelines
        div
          ul
            li Business Name: The name of the business as it appears on the receipt.
            li Short Name: If the business has multiple locations, the short name is used to differentiate between them.
        v-text-field(v-model="name", label="Business Name", counter="35")
        v-text-field(
          v-model="short_name",
          label="Short Name (optional)",
          counter="20"
        )
      v-card-actions
        v-btn(type="submit", block, :disabled="!name", color="secondary") Save
</template>

<script>
export default {
  props: {
    biz: { type: Object, default: null },
  },
  data() {
    return {
      dialog: false,
      name: "",
      short_name: "",
      loading: false,
    };
  },
  methods: {
    open() {
      this.name = this.biz?.name || "";
      this.short_name = this.biz?.short_name || "";
      this.dialog = true;
    },
    async save() {
      this.loading = true;
      try {
        this.validate();
        const params = {
          criteria: { _id: this.biz._id },
          action: {
            $set: {
              name: this.name.trim(),
              short_name: this.short_name.trim(),
            },
          },
        };
        const result = await this.$api.biz.update(params);
        this.$store.dispatch("setBiz", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    validate() {
      if (!this.biz?._id) throw new Error("Invalid business");
      this.name = this.name.trim();
      this.short_name = this.short_name.trim();
      if (!this.name) throw new Error("Name is required");
      if (this.name.length > 35) throw new Error("Name is too long");
      if (!this.short_name) return;
      if (this.short_name.length > 20)
        throw new Error("Short name is too long");
    },
  },
};
</script>
