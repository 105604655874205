<template lang="pug">
v-card(flat)
  v-md-preview.prose.prose-small.pa-3(:text="content")
  v-card-actions
    v-spacer
    v-btn(@click="$emit('edit', item)", icon, color="secondary")
      v-icon(small) mdi-pencil
    v-btn(@click="handleDelete", icon, color="error", :loading="loading")
      v-icon(small) mdi-delete
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    content() {
      const start = new Date(this.item.start);
      const end = new Date(this.item.end);
      return `<h2>${
        this.item.title
      }</h2> <i>From ${start.toDateString()} to ${end.toDateString()}</i> ${
        this.item.content
      }`;
    },
  },
  methods: {
    handleDelete() {
      confirm("Are you sure you want to delete this item?") &&
        this.submitDelete();
    },
    async submitDelete() {
      this.loading = true;
      try {
        const id = this.item._id;
        await this.$api.b.domainAnnouncement.delete(id);
        this.$emit("delete", this.item);
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>