<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="biz && weeklySpecial")
    v-toolbar(dense, flat)
      .subtitle-2 {{ title }} a Weekly Special
    v-divider
    v-card-text
      v-form(@submit.prevent="save")
        v-row(dense)
          v-col(cols="6")
            v-text-field(label="Title", v-model="weeklySpecial.name")
          v-col(cols="6")
            v-text-field(
              v-model="weeklySpecial.pct",
              label="Discount Percentage",
              suffix="% off"
            )
        v-text-field(label="Description", v-model="weeklySpecial.description")
        v-select(
          v-model="weeklySpecial.days",
          :items="weekdays",
          label="Days",
          multiple,
          color="secondary"
        )
        v-select(
          v-model="weeklySpecial.courses",
          :items="courses",
          label="Courses",
          multiple,
          color="secondary",
          :hint="hint",
          persistent-hint
        )
        v-select(
          v-model="weeklySpecial.dishes",
          :items="dishes",
          label="Dishes",
          multiple,
          color="secondary",
          :hint="hint",
          persistent-hint
        )
        .text-center.my-3
          .subtitle-2 Set Expiration Date
          v-date-picker.my-3(
            v-model="expiry",
            :min="minDate",
            color="secondary",
            no-title
          )
        v-btn(block, color="secondary", :loading="loading", type="submit") Save
      .mt-2
      div We don't charge any fee on weekly special discounts
      div It will apply to any orders on the selected days; with no limit on customers' ordering history.
</template>

<script>
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      minDate: moment().format("YYYY-MM-DD"),
      weeklySpecial: null,
      expiry: null,
      weekdays: [
        { text: "Monday", value: 1 },
        { text: "Tuesday", value: 2 },
        { text: "Wednesday", value: 3 },
        { text: "Thursday", value: 4 },
        { text: "Friday", value: 5 },
        { text: "Saturday", value: 6 },
        { text: "Sunday", value: 0 },
      ],
      hint: "Leave empty to apply to all",
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    title() {
      if (this.weeklySpecial?._id) return "Edit";
      else return "Create";
    },
    dishes() {
      return this.biz?.dishes
        ?.map((d) => ({
          text: d.name,
          value: d._id,
        }))
        ?.sort((a, b) => a.text.localeCompare(b.text));
    },
    courses() {
      return this.biz?.courses
        ?.map((c) => ({
          text: c.name,
          value: c._id,
        }))
        ?.sort((a, b) => a.text.localeCompare(b.text));
    },
  },
  mounted() {
    EventBus.$on("edit-weekly-special", this.open);
  },
  destroyed() {
    EventBus.$off("edit-weekly-special", this.open);
  },
  methods: {
    ...mapActions(["addWeeklySpecial", "updateWeeklySpecial"]),
    open(data) {
      if (!this.biz) return;
      if (data) {
        this.weeklySpecial = JSON.parse(JSON.stringify(data));
        this.expiry = moment(data.end).format("YYYY-MM-DD");
      } else {
        this.weeklySpecial = {
          biz: this.biz._id,
          pct: 5,
          name: "",
          description: "",
          days: [],
          courses: [],
          dishes: [],
          expiry: null,
        };
        this.expiry = moment().add(3, "months").format("YYYY-MM-DD");
      }
      this.dialog = true;
    },
    validate() {
      if (!this.weeklySpecial.pct)
        throw new Error("Discount percentage is required");
      if (this.weeklySpecial.pct < 5 || this.weeklySpecial.pct > 80)
        throw new Error("Discount percentage should be between 5% to 80%");
      if (!this.weeklySpecial.name) throw new Error("Name is required");
      if (!this.weeklySpecial?.days?.length) throw new Error("Day is required");
      this.weeklySpecial.days.sort();
      this.weeklySpecial.end = moment(this.expiry).endOf("day").valueOf();
    },
    async save() {
      /// validate
      this.loading = true;
      try {
        this.validate();
        if (this.weeklySpecial._id) {
          await this.update();
        } else {
          await this.create();
        }
        this.dialog = false;
      } catch (e) {
        return this.$toast.error(e.message);
      }
      this.loading = false;
    },
    async create() {
      if (!this.biz) return;
      const result = await this.$api.discount.weeklySpecial.create(
        this.weeklySpecial
      );
      this.addWeeklySpecial(result);
    },
    async update() {
      if (!this.biz || !this.weeklySpecial._id) return;
      const params = {
        criteria: { _id: this.weeklySpecial._id },
        action: { $set: this.weeklySpecial },
      };
      const result = await this.$api.discount.weeklySpecial.update(params);
      this.updateWeeklySpecial(result);
    },
  },
};
</script>
