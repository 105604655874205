<template lang="pug">
.s-vstack
  h1 Domain
  Gallery
  Announcement
</template>

<script>
import Gallery from "./Gallery";
import Announcement from "/libs/components/Biz/Domain/Announcement";

export default {
  components: { Gallery, Announcement },
};
</script>