<template lang="pug">
v-dialog(v-model="dialog", width="500", scrollable)
  v-card(v-if="menu")
    v-toolbar(dense, flat)
      .subtitle-2 {{ title }}
    v-card-text
      v-form(@submit.prevent="submit")
        v-text-field(
          label="Name",
          placeholder="e.g., Breakfast, Main, Lunch Special",
          v-model="menu.name"
        )
        v-textarea(
          label="Description (optional)",
          v-model="menu.description",
          rows="1",
          :auto-grow="true",
          counter="200"
        )
        v-switch(
          label="Special Hours",
          v-model="menu.selectedTime",
          color="secondary",
          hint="Select if the menu offers only in specific hours. e.g., Lunch Special",
          persistent-hint
        )
        div(v-if="menu.selectedTime")
          DaysPicker(v-model="menu.date")
          SchedulePicker.my-2(v-model="menu.schedule")
        v-row(dense, wrap)
          v-col(cols="6")
            v-switch(
              label="Available Online",
              v-model="online",
              color="secondary",
              dense
            )
          v-col(cols="6")
            v-switch(
              label="Sync to Third Party",
              v-model="menu.third_party",
              color="secondary",
              dense
            )
          v-col(cols="6")
            v-switch(
              label="Run out (86'd)",
              v-model="menu.ooo",
              color="red",
              dense
            )
        v-switch(v-model="menu.catering", label="Catering", color="secondary")
        v-alert(type="success", v-if="menu.catering") By selecting catering, this menu will show up only in the catering category online. We charge commission fee to online catering sales. Please refer to
          a.mx-1(
            href="https://www.selflane.com/products/pricing",
            target="_blank"
          ) our pricing
          span for details.
        .pl-2.red--text(
          v-for="(error, index) in errors",
          :key="index + 'index'"
        ) {{ error }}
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  name: "MenuForm",
  data() {
    return {
      menu: null,
      online: true,
      dialog: false,
      errors: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    title() {
      return this.menu?._id ? "Edit Menu" : "Add Menu";
    },
  },
  mounted() {
    EventBus.$on("edit-menu", this.open);
  },
  destroyed() {
    EventBus.$off("edit-menu", this.open);
  },
  methods: {
    open(val) {
      if (!val) return;
      this.menu = JSON.parse(JSON.stringify(val));
      if (!this.menu.schedule?.length) {
        this.menu.schedule = [{ range: [480, 1200] }];
      }
      this.errors = [];
      this.dialog = true;
      this.online = !this.menu.controlled;
    },
    async submit() {
      this.errors = [];
      this.menu.controlled = !this.online;
      /// validate
      if (!this.biz || !this.menu) {
        this.errors = ["Invalid data"];
        return;
      }
      if (!this.menu.name) this.errors.push("Name is required");
      this.menu.name = this.menu.name.trim();
      const some = this.biz.menus.some(
        (o) =>
          o._id !== this.menu._id &&
          o.name.toLowerCase() === this.menu.name.toLowerCase()
      );
      if (some) {
        this.errors.push("Duplicate name. A menu with the same name is found.");
      }
      if (
        this.menu.selectedTime &&
        (!this.menu.date?.length || !this.menu.schedule?.length)
      ) {
        this.errors.push("Please set days and hours for the special menu.");
      }
      if (this.errors?.length) return;
      this.loading = true;
      let params;
      if (this.menu._id) {
        params = {
          criteria: { _id: this.biz._id, "menus._id": this.menu._id },
          action: { $set: { "menus.$": this.menu } },
        };
      } else {
        params = {
          criteria: { _id: this.biz._id },
          action: { $push: { menus: this.menu } },
        };
      }
      try {
        const data = await this.$api.biz.update(params);
        this.$store.dispatch("setBiz", data);
        this.dialog = false;
      } catch (e) {
        this.errors = [e.response?.data || e.message];
      }
      this.loading = false;
    },
  },
};
</script>
