<template lang="pug">
Block(:title="'Catering'", :items="items")
  Form
</template>

<script>
import { mapGetters } from "vuex";
import Block from "./../Block";
import Form from "./Form";

export default {
  components: { Form, Block },
  computed: {
    ...mapGetters(["biz"]),
    leadtime() {
      const time = this.biz.catering?.time;
      if (!time) return "";
      if (time.unit === "day") {
        if (time.value === 1) {
          return `One day before ${time.cutoff}:00`;
        } else {
          return `${time.value} days before ${time.cutoff}:00`;
        }
      }
      if (time.value === 1) {
        return `One hour`;
      } else {
        return `${time.value} hours`;
      }
    },
    items() {
      let data = [];
      const currency = this.$options.filters.currency;
      if (this.biz.catering?.minimum_purchase) {
        data.push({
          title: "Minimum Purchase",
          content: currency(this.biz.catering.minimum_purchase),
        });
      }
      data.push({ title: "Lead time", content: this.leadtime });
      return data;
    },
  },
};
</script>
