<template lang="pug">
.s-vstack
  .d-flex.flex-row.align-center
    h2 Announcement
    v-spacer
    IconBtn(@click="handleEdit", title="Announcement", icon="mdi-plus")
  div
    p {{ hided }} Old Announcement(s) Hidden
    v-switch(v-model="hidePast", label="Hide Past", color="secondary")
  v-row
    v-col(:cols="12 / 4", v-for="item in filtered", :key="item._id")
      Cell(:item="item", @edit="handleEdit", @delete="deleteItem")
  Form(ref="form", :domain="domain", @add="addItem", @update="updateItem")
</template>

<script>
import { mapGetters } from "vuex";
import Cell from "./Cell";
import Form from "./Form";

export default {
  components: { Form, Cell },
  data() {
    return {
      items: [],
      hidePast: true,
    };
  },
  computed: {
    ...mapGetters(["biz", "domain"]),
    domainId() {
      return this.domain._id;
    },
    sorted() {
      return this.items?.toSorted((a, b) => b.start - a.start) || [];
    },
    filtered() {
      return this.sorted.filter((i) => !this.hidePast || i.end > Date.now());
    },
    hided() {
      return this.items.length - this.filtered.length;
    },
  },
  watch: {
    domainId() {
      this.load();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      if (!this.domainId) {
        this.items = [];
        return;
      }
      try {
        this.items = await this.$api.b.domainAnnouncement.list({
          criteria: { domain: this.domainId },
        });
      } catch (e) {
        this.$toast.error(e.message);
      }
    },
    addItem(item) {
      this.items.push(item);
    },
    updateItem(item) {
      const index = this.items.findIndex((i) => i._id === item._id);
      this.$set(this.items, index, item);
    },
    deleteItem(item) {
      const index = this.items.findIndex((i) => i._id === item._id);
      this.items.splice(index, 1);
    },
    handleEdit(item) {
      this.$refs.form.open(item);
    },
  },
};
</script>