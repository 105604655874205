/**
 * A form to create or update an announcement.
 */

<template lang="pug">
v-dialog(v-model="dialog", width="600")
  v-card
    v-card-title {{ title }}
    v-form(@submit.prevent="submit")
      v-card-text
        v-textarea(v-model="announcement.title", label="Title", rows="2")
        v-row
          v-col(cols="6")
            DateFieldPicker(v-model="startDate", label="Start Date")
          v-col(cols="6")
            DateFieldPicker(v-model="endDate", label="End Date")
      v-card-actions
        v-btn(@click="dialog = false") Cancel
        v-btn(type="submit", :loading="loading", color="secondary") Save
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      announcement: {},
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["domain"]),
    title() {
      return this.announcement._id ? "Edit Announcement" : "Add Announcement";
    },
  },
  methods: {
    open(input) {
      this.dialog = true;
      if (input) {
        this.announcement = JSON.parse(JSON.stringify(input));
      } else {
        this.announcement = {
          title: "",
          start: Date.now(),
          end: Date.now(),
        };
      }
      this.startDate = moment(this.announcement.start).format("YYYY-MM-DD");
      this.endDate = moment(this.announcement.end).format("YYYY-MM-DD");
    },
    validate() {
      if (!this.domain) throw new Error("Domain is not set");
      this.announcement.domain = this.domain._id;

      if (!this.startDate) throw new Error("Start date is required");
      if (!this.endDate) throw new Error("End date is required");
      this.announcement.start = moment(this.startDate).startOf("day").valueOf();
      this.announcement.end = moment(this.endDate).endOf("day").valueOf();
      if (this.announcement.start >= this.announcement.end) {
        throw new Error("Start date must be before end date");
      }
      if (this.announcement.end < Date.now()) {
        throw new Error("End date must be in the future");
      }

      this.announcement.title = this.announcement.title?.trim();
      this.announcement.content = this.announcement.content?.trim();
      if (!this.announcement.title) throw new Error("Title is required");
    },
    async submit() {
      this.loading = true;
      try {
        this.validate();
        if (this.announcement._id) {
          const result = await this.$api.b.domainAnnouncement.put(
            this.announcement._id,
            this.announcement
          );
          this.$emit("update", result);
        } else {
          const result = await this.$api.b.domainAnnouncement.create(
            this.announcement
          );
          this.$emit("add", result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>