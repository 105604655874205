<template lang="pug">
simple-table(v-if="weekly_specials && weekly_specials.length")
  thead
    tr
      th Discount %
      th Day(s)
      th Name
      th Courses
      th Dishes
      th.text-right End
      th
  tbody
    tr(
      v-for="(item, index) in weekly_specials",
      :key="index",
      @click="open(item)",
      role="button"
    )
      th {{ item.pct }}% off
      td {{ item.days | day(true) }}
      td {{ item.name }}
      td {{ getCourses(item) }}
      td {{ getDishes(item) }}
      td.text-right {{ item.end | date }}
      td.text-right
        v-btn(icon, @click.stop="handleDelete(item)")
          v-icon(small, color="error") mdi-delete
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["weekly_specials", "biz"]),
  },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setWeeklySpecials", "removeWeeklySpecial"]),
    async load() {
      if (!this.biz) return;
      const criteria = { biz: this.biz._id };
      const result = await this.$api.discount.weeklySpecial.list({ criteria });
      this.setWeeklySpecials(result);
    },
    open(item) {
      EventBus.$emit("edit-weekly-special", item);
    },
    handleDelete(item) {
      confirm("Do you want to delete this special offer?") &&
        this.deleteOne(item);
    },
    async deleteOne(item) {
      const params = { weeklySpecialId: item._id };
      await this.$api.discount.weeklySpecial.custom("deleteOne", params);
      this.removeWeeklySpecial(item);
    },
    getDishes({ dishes, courses }) {
      if (!dishes?.length) {
        if (courses?.length) return "-";
        return "All";
      }
      return dishes
        .map((id) => this.biz?.dishes?.find((d) => d._id === id)?.name)
        .filter((d) => d)
        .sort((a, b) => a.localeCompare(b))
        .join(", ");
    },
    getCourses({ dishes, courses }) {
      if (!courses?.length) {
        if (dishes?.length) return "-";
        return "All";
      }
      return courses
        .map((id) => this.biz?.courses?.find((c) => c._id === id)?.name)
        .filter((c) => c)
        .sort((a, b) => a.localeCompare(b))
        .join(", ");
    },
  },
};
</script>
