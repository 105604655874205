<template lang="pug">
v-card(flat, :loading="loading")
  v-toolbar(flat, dense)
    .event-title {{ item.title }}
    v-spacer
    v-btn(icon, @click="handleEdit", color="secondary")
      v-icon mdi-pencil
    ToggleOnline(:item="item")
    v-btn(icon, @click.stop="handleDelete", color="error", :loading="loading")
      v-icon mdi-delete
  v-card-text
    v-row(dense)
      v-col(cols="12", md="8")
        .caption Created: {{ item.created | date }}
        Address(:item="item")
        Items(:items="item.items")
        .subtitle-2(v-if="item.event_time") Event time: {{ item.event_time.begin | datetime }} - {{ item.event_time.end | datetime }}
        .subtitle-2(v-if="item.sale_time") Sales time: {{ item.sale_time.begin | datetime }} - {{ item.sale_time.end | datetime }}
        div {{ item.detail_short }}
        .mt-3
        div(v-if="item.external_policy") Ext. Policy:
          a.ml-2(:href="item.external_policy", target="_blank") {{ item.external_policy }}
        .mt-3
        a(:href="url", target="_blank") Event Public Page
        br
        a(:href="countDownURL", target="_blank") Event Countdown Page
        QrcodeVue#qrcode.mt-3(
          :value="url",
          size="150",
          renderAs="canvas",
          level="H",
          ref="qrCodeUrl"
        )
      v-col(cols="12", md="4")
        Poster(:item="item")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import QrcodeVue from "qrcode.vue";
import { mapActions } from "vuex";
import Address from "./Address.vue";
import Items from "./Items";
import Poster from "./Poster";
import ToggleOnline from "./ToggleOnline";

export default {
  components: { ToggleOnline, Items, Poster, Address, QrcodeVue },
  props: ["item"],
  data() {
    return { loading: false };
  },
  computed: {
    url() {
      return "https://www.selflane.com/event/" + this.item._id;
    },
    countDownURL() {
      return "https://www.selflane.com/event_count/" + this.item._id;
    },
  },
  methods: {
    ...mapActions(["removeEvent"]),
    handleEdit() {
      EventBus.$emit("edit-event", this.item);
    },
    handleDelete() {
      confirm("Do you really want to delete this event?") && this.deleteOne();
    },
    async deleteOne() {
      if (!this.item?._id) return;
      this.loading = true;
      try {
        const result = await this.$api.event.entry.delete(this.item._id);
        this.removeEvent(result);
        this.$toast.success("Event deleted.");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.event-title
  font-size: 18px
  font-weight: 800
  color: #39364F

#qrcode
  border-radius: 6px
  overflow: hidden
</style>