<template lang="pug">
Block(title="Links")
  .block-cell-container
    Cell(
      v-for="(item, index) in items",
      :key="index",
      :title="item.title",
      :url="item.content",
      :hint="item.hint"
    )
</template>

<script>
import { mapGetters } from "vuex";
import Block from "../Block.vue";
import Cell from "./Cell.vue";

export default {
  components: { Block, Cell },
  computed: {
    ...mapGetters(["biz"]),
    online_store() {
      return "https://www.selflane.com/bizs/" + this.biz.url;
    },
    catering() {
      const some = this.biz?.menus?.some((menu) => menu.catering);
      if (!some) return null;
      return "https://www.selflane.com/catering/" + this.biz.url;
    },
    booking() {
      return "https://www.selflane.com/rs/" + this.biz.url;
    },
    items() {
      return [
        { title: "Online Store Page", content: this.online_store },
        {
          title: "Catering Page",
          content: this.catering,
          hint: "Please add catering menus in Menu section",
        },
        { title: "Table Booking Page", content: this.booking },
      ];
    },
  },
};
</script>

<style lang="sass" scoped>
.block-cell-container
  display: flex
  flex-direction: column
  gap: 20px
.block-cell
  display: flex
  flex-direction: column
  gap: 2px
  .cell-title
    color: #666
    font-size: 0.8rem
    font-weight: 600
    display: flex
    align-items: center
  .cell-content
    font-size: 0.8rem
    font-weight: 500
    color: black
    overflow: hidden
    display: -webkit-box
    -webkit-box-orient: vertical
    -webkit-line-clamp: 1
</style>